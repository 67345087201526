import { formatPostcode } from "helper/data/shops";
import Log from "helper/sentry";
import { getCurrentUserUuid } from "helper/user";
const dataLayerKey = 'dataLayer';

export const logAnalytics = (key: string, item?: any) => {
  const input = item || {};
  const user_id = getCurrentUserUuid(false);

  const logExcluded = ['shop_impression'];
  if (!logExcluded.includes(key)) {
    Log.info(key, 'analytics', { ...input, user_id });
  }

  if (!process.env.NEXT_PUBLIC_DEVO_ENV) return;
  push({ ...input, user_id, event: key });
};

export const identifyToMixpanel = (userId: string = null) => {
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when identifying.`, 'mixpanel', null);
  const _userId = userId || getCurrentUserUuid(false);
  if (!_userId) return;
  mixpanel.identify(_userId);
  Log.info(`Identify Mixpanel "${_userId}".`, 'mixpanel', null);
};

var userPropertyTimeout = null;
export const setMixpanelUserProperty = (value: any, flush: boolean = true) => {
  const data = { ...value, last_session: (new Date()).toISOString() };
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when setting user properties.`, 'mixpanel', data);

  mixpanel.people.set(data);
  Log.info(`Set Mixpanel user properties`, 'mixpanel', { data, flush });

  if (flush === false) return;
  if (userPropertyTimeout) clearTimeout(userPropertyTimeout);
  userPropertyTimeout = setTimeout(() => {
    Log.info('Flush identify triggered', 'mixpanel', null);
    identifyToMixpanel();
  }, 5000); // 10 seconds
};

export const setMixpanelSuperProperty = (key: string, value: any) => {
  if (!process.env.NEXT_PUBLIC_DEVO_ENV) return;
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when setting super property "${key}".`, 'mixpanel', { key, value });

  var property = {};
  property[key] = value || '';
  mixpanel.register(property);
  Log.info(`Set Mixpanel super property "${key}" = "${value || ''}".`, 'mixpanel', null);
};

export const push = (item) => {
  try {
    dataLayer()?.push(item);
  } catch {
    Log.warning(`Error pushing ${item?.event || 'event'} to dataLayer`, 'gtm', item);
  }
}

export const dataLayer = () => {
  window[dataLayerKey] = window[dataLayerKey] || [];
  return window[dataLayerKey];
};

export const formatProduct = (
  item,
  position,
  storeName,
  allCategories,
  quantity,
) => {
  const id = item.product?.upc?.toString();
  const name = item.product?.name;
  const categoryId = item.product?.category?.id;
  const category = formatCategory(categoryId, allCategories, null);

  if (!id || !name || !categoryId || !category) {
    return null;
  }

  var data = {
    id: id,
    name: name,
    category: category,
    price: item.devoPrice.toString(),
  };

  if (storeName) data['list'] = storeName;
  if (position) data['position'] = position+1;
  if (quantity) data['quantity'] = quantity;

  return data;
}

export const formatCategory = (categoryId, allCategories, current) => {
  if (!allCategories) return current || '';
  if (categoryId === 1) {
    return current;
  } else {
    const category = allCategories.find(c => c.id === categoryId );
    if (category) {
      if (current) {
        const updated = `${category.name}/${current}`;
        return formatCategory(category.parentId, allCategories, updated);
      } else {
        return formatCategory(category.parentId, allCategories, category.name);
      }
    } else {
      return current;
    }
  }
};

export const formatStore = (store) => {
  return store?.name;
};

export const splitPostcode = (input: string) => {
  const post_code = formatPostcode(input);
  const split = post_code.split(' ');
  if (split.length > 1) {
    const district = split[0];
    const sector = `${district} ${split[1][0]}`;
    return { post_code, district, sector };
  } else {
    return { post_code };
  }  
}
