export const HOME_ROUTE = '/';
export const SHOPS_ROUTE = '/shops/[postcode]';
export const SHOPS_BY_AREA_ROUTE = '/area/[areaName]';

export const PROFILE_ROUTE = '/profile';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/join';
export const FORGOT_PASSWORD_ROUTE = '/auth/forgot';

export const REFERRAL_DOWNLOAD_APP_ROUTE = '/referral/app';

export const ORDERS_ROUTE = '/orders';
export const CHECKOUT_ROUTE = '/checkout';

export const HELP_ROUTE = '/help';

export const APP_STORE_ROUTE = '/app';

export const CATEGORY_ICE_CREAM_ROUTE = '/category/ice-cream-delivery';
export const CATEGORY_SNACK_ROUTE = '/category/snack-delivery';
export const CATEGORY_ALCOHOL_ROUTE = '/category/alcohol-delivery';
export const CATEGORY_CIGARETTE_ROUTE = '/category/cigarette-and-tobacco-delivery';
export const CATEGORY_MILK_ROUTE = '/category/milk-delivery';