
export const isItemShopApplicableToBasket = (item, items) => {
  var result = true;
  items.forEach(x => {
    if (x.shopUuid !== item.shopUuid) result = false;
  });
  return result;
};

export const formatPostcode = (input: string): string => {
  if (!input) return null;
  var postcode = formatCleanPostcode(input);
  if (postcode.length > 4) {
    const position = postcode.length - 3;
    postcode = [postcode.slice(0, position), ' ', postcode.slice(position)].join('');
  }
  return postcode;
}

export const formatCleanPostcode = (input: string): string => {
  if (!input) return null;
  return input.toUpperCase().trim().replace(/\s/g, '').replace(/\W/g, '')
}

export const districtFromPostcode = (postcode: string): string => {
  const formatted = formatPostcode(postcode);
  return formatted?.split(' ')[0] || formatted || '';
};